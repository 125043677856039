/* * 업무구분: 신탁>영업>변경/해지/이체>송금계좌등록/해지 * 화 면 명: MSPTS213M(TFIO42250.xfdl: 1190) * 화면설명: 송금계좌등록/해지 * 작 성 일: 2023.03.24 * 작 성 자: 박은희 (전면개정 -> refined by
anarchi) */ /********************************************************************************* * Vue 화면 templates 설정 영역
*********************************************************************************/
<template>
  <ur-page-container class="fts" :show-title="false" title="송금계좌등록" :topButton="true">
    <!-- header start -->
    <ts-header ref="tsHeader" :propObj="pHeaderObj"></ts-header>
    <!-- header end -->
    <!-- .fts-main -->
    <div class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="left gap40">
            <div class="wrap-input row">
              <label> 종합계좌번호 </label>
              <!-- (공통) 계좌 및 금액 관련 숫자입력 키패드 교체 적용, applyed by anarchi (2023.0318) // -->
              <!-- <mo-text-field 
                v-model="ds_acno.tacno"
                class="w130"
                maxlength="7"
                @keyup="fn_SearchValid ($event)" 
              /> -->
              <mo-decimal-field v-model="ds_acno.tacno" numeric mask="#######-###" class="w130" maxlength="7" @keyup="fn_SearchValid($event)" />
              <mo-button class="btn-pop-download" @click="fn_OpenMSPTS107P"> </mo-button>
              <mo-text-field class="w130" disabled v-model="ds_acno.cnm" />
              <!--<mo-text-field class="w130" v-model="ds_acno.csId"/>-->
            </div>
            <div class="wrap-input row">
              <label> 비밀번호 </label>
              <!-- 보안 키패드 (모바일전용) //-->
              <m-trans-key-input
                v-if="isMobile"
                v-model="edt_pwd"
                type="numberMax4"
                class="login-password"
                maxlength="4"
                placeholder="비밀번호"
                :start="'0'"
                :end="'-1'"
                dialog="Y"
                :isClear="lv_isClear"
                @masked-txt="fn_SetMaskedTxt"
              >
              </m-trans-key-input>
              <!-- 일반 키입력 //-->
              <mo-text-field v-else v-model="edt_pwd" class="login-password" maxlength="4" placeholder="비밀번호" type="password" password @keyup="fn_PwdValid" />
            </div>
          </div>
          <div class="right">
            <div class="wrap-button row">
              <mo-button @click="fn_init"> 초기화 </mo-button>
              <mo-button primary @click="fn_searchList"> 조회 </mo-button>
            </div>
          </div>
        </div>
      </ur-box-container>
      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title flex-sb align-end">
          <em class="colorR fs12 w100p" v-html="processMsg"></em>
          <div class="wrap-button result mt-5 mb6">
            <mo-button :disabled="isRegBtnDisabled" @click="fn_OpenMSPTS204P"> 신규계좌 등록 </mo-button>
            <mo-button :disabled="isAmlBtnDisabled" @click="fn_OpenMSPTS205P"> AML </mo-button>
            <!-- 전자서식(리포트)출력 연계개발/검증을 위한 임시 추가, added by anarchi, 2023.07.19 //-->
            <!--<mo-button @click="fn_confirmPrintTrue" primary> 전자문서 조회 </mo-button>-->
            <!--          
            <mo-button  @click="fn_OpenMSPTS205P">AML</mo-button>  
              <mo-button @click="fn_OpenMSPTS319P">계약승인알림</mo-button>  
              <mo-button @click="fn_OpenMSPTS205P">AML</mo-button>  
              <mo-button @click="fn_OpenMSPTS311P">조력자등록대상</mo-button>            
              <mo-button @click="fn_OpenMSPTS320P">수수료입금알림</mo-button>  
            -->
          </div>
        </div>
        <div class="wrap-table h-scroll">
          <table class="table row-type">
            <thead>
              <tr>
                <th class="w70">등록상품</th>
                <th class="w100">등록/해지처리</th>
                <th>예금주명</th>
                <th>은행명</th>
                <th>계좌번호</th>
                <th>등록채널</th>
                <th class="w95">처리일자</th>
                <th class="w80">처리자</th>
                <th>지급계좌지정</th>
              </tr>
            </thead>
            <tbody v-for="(row, i) in ds_list" :key="i">
              <tr>
                <td>
                  <mo-badge class="badge-type type03" :class="fn_reClass(row.relsYn)" :text="fn_reTitle('1', row.relsYn)" />
                </td>
                <td>
                  <mo-button @click="fn_sendAcctSelectedRow(i)">
                    {{ fn_reTitle("2", row.relsYn) }}
                  </mo-button>
                </td>
                <td>{{ row.bnkAccNm }}</td>
                <td>{{ row.bnkNm }}</td>
                <!-- <td> {{row.bnkAcno}} </td> -->
                <!-- 계좌번호 매스킹 처리 (보안성검토 조치), modified by anarchi (2023.09.05) -->
                <td>{{ bnkAcnoMask(row.bnkAcno) }}</td>
                <td>{{ row.accnTrtPathCdNm }}</td>
                <td>{{ dateYyMmDdFormat(stringDateFormat(row.procDate)) }}</td>
                <td>{{ row.procEnnm }}</td>
                <td>{{ row.acctNo }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>
      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <mo-button primary size="large" @click="fn_sign"> 확인 </mo-button>
        </div>
      </ur-box-container>
    </div>
    <!-- //.fts-main -->
    <ts-alert-popup ref="alertPopup" :popupObj="pAlertPopupObj"></ts-alert-popup>
    <!-- popup106: 책임자 승인 //-->
    <msp-ts-106p ref="popup106" :popup106Obj="pPopup106Obj" @ts-alert-popup="fn_AlertPopup" @ts-popup106-callback="fn_Popup106Back"></msp-ts-106p>
    <!-- popup107: 고객 조회 //-->
    <msp-ts-107p ref="popup107" :popup107Obj="pPopup107Obj" @ts-alert-popup="fn_AlertPopup" @ts-popup107-callback="fn_Popup107Back"></msp-ts-107p>
    <!-- popup204: 송금계좌 등록 //-->
    <msp-ts-204p ref="popup204" :popup204Obj="pPopup204Obj" @ts-alert-popup="fn_AlertPopup" @ts-popup204-callback="fn_Popup204Back"></msp-ts-204p>
    <!-- popup205: AML송수신승인처리 //-->
    <msp-ts-205p ref="popup205" :popup205Obj="pPopup205Obj" @ts-alert-popup="fn_AlertPopup" @ts-popup205-callback="fn_Popup205Back"></msp-ts-205p>
    <!-- 계약승인알림_P-->
    <!--
  <msp-ts-319p
    ref="popup319"
    :popup319Obj="pPopup319Obj"
    @ts-alert-popup="fn_AlertPopup"
    @ts-popup319-callback="fn_Popup319Back"    
  ></msp-ts-319p>  
-->
    <!--조력자등록대상_P -->
    <!--
  <msp-ts-311p
    ref="popup311"
    :popup311Obj="pPopup311Obj"
    @ts-alert-popup="fn_AlertPopup"
    @ts-popup311-callback="fn_Popup311Back"    
  ></msp-ts-311p>  
 -->
    <!--수수료입금알림_P -->
    <!--
  <msp-ts-320p
    ref="popup320"
    :popup320Obj="pPopup320Obj"
    @ts-alert-popup="fn_AlertPopup"
    @ts-popup320-callback="fn_Popup320Back"    
  ></msp-ts-320p>  
  -->
  </ur-page-container>
</template>

<script>
/******************************************************************************************
 * 공통 라이브러리 INCLUDE 영역
 ******************************************************************************************/
import _ from "lodash";
import _cloneDeep from "lodash/cloneDeep";
// import moment from "moment"

import TSAlertPopup from "~/src/ui/ts/comm/TSAlertPopup";
import TSCommUtil from "~/src/ui/ts/comm/TSCommUtil";
import TSHeader from "~/src/ui/ts/comm/TSHeader"; // header 영역 (공통)
import TSInfoUtil from "~/src/ui/ts/comm/TSInfoUtil"; // Report (공통)
import TSServiceUtil from "~/src/ui/ts/comm/TSServiceUtil";

import MSPTS106P from "@/ui/ts/MSPTS106P"; //고객_P
import MSPTS107P from "@/ui/ts/MSPTS107P"; //고객_P
import MSPTS204P from "@/ui/ts/MSPTS204P"; //송금계좌등록_P
import MSPTS205P from "@/ui/ts/MSPTS205P"; //AML송수신승인처리_P
// import MSPTS319P from "@/ui/ts/MSPTS319P" //계약승인알림_P
// import MSPTS311P from "@/ui/ts/MSPTS311P" //조력자등록대상_P
// import MSPTS320P from "@/ui/ts/MSPTS320P" //수수료입금알림_P

const defaultAcnoList = {
  csId: "",
  cno: "",
  cnm: "",
  rcnoKindTc: "",
  rcno: "",
  cono: "",
  genTaxtTc: "",
  persnBusiMnBsno: "",
  corpYn: "",
  csIvtdRatingC: "",
  procDt: "",
  valdTrmYys: "",
  ques10: "",
  tacno: "",
  qtnSeq: "",
  age: "",
  tacnoBnkbSeq: "",
  regDate: "",
  tassIssncAccYn: "",
};
const defaultAcno = {
  csId: "",
  cno: "",
  cnm: "",
  rcnoKindTc: "",
  rcno: "",
  cono: "",
  genTaxtTc: "",
  persnBusiMnBsno: "",
  corpYn: "",
  csIvtdRatingC: "",
  procDt: "",
  valdTrmYys: "",
  ques10: "",
  tacno: "",
  qtnSeq: "",
  age: "",
  tacnoBnkbSeq: "",
  regDate: "",
  tassIssncAccYn: "",
  bnkbSeq: "",
  accSeq: "",
  baseDate: "",
};
const defaultList = {
  stipSeq: "",
  csId: "",
  trBkcd: "",
  bnkAcno: "",
  bnkAccNm: "",
  relsYn: "",
  regDate: "",
  relsDate: "",
  regMnEmno: "",
  custAccnRltnCdNm: "",
  bnkNm: "",
  accnTrtPathCdNm: "",
  procDate: "",
  acctNo: "",
  bncrVtTacno: "",
  custAccnRltnCd: "",
  tacno: "",
  bncrVtTacnoYn: "",
  acctNo2Yn: "",
  insrAccRegYn: "",
  procEnnm: "",
  regMnEmnoNm: "",
};
const defaultInsert = {
  stipSeq: "",
  bnkAccDepoNm: "",
  trBkcd: "",
  bnkAcno: "",
  bnkAccNm: "",
  relsYn: "",
  regDate: "",
  relsDate: "",
  regMnEmno: "",
  aprvNo: "",
  tacno: "",
  procDate: "",
  csId: "",
  eaiRegProcYn: "",
  procCls: "",
  bnkNm: "",
  insrAccRegYn: "",
};
const defaultSearch = {
  csId: "",
  tacno: "",
  pwd: "",
  pwdYn: "",
};
const defaultUpdate = {
  stipSeq: "",
  csId: "",
  relsDate: "",
  trBkcd: "",
  bnkAcno: "",
  procCls: "",
  bnkNm: "",
  aprvNo: "",
};
const defaultCmb5095 = {
  cNm: "",
  c: "",
};
const defaultAcctNm = {
  trBkcd: "",
  bnkAcno: "",
  bnkAccNm: "",
  procEmno: "",
  csId: "",
};
const defaultDetail = {
  aprvNo: "",
  amlProcYn: "", // 임시 값 설정
  amlProcNo: "",
};
const defaultUpdate01 = {
  tacno: "",
  trBkcd: "",
  bnkAcno: "",
};
const defaultPbpr = {
  pbprTp: "",
  pbprCntl: "",
  pbprPageNo: "",
  pbprLineNo: "",
  pbprOutLen: "",
  pbprData: "",
  pbprPrtCnt: "",
};
const defaultPrintAcct = {
  stipSeq: "",
  csId: "",
  relsDate: "",
  trBkcd: "",
  bnkAcno: "",
  procCls: "",
  trBkcdNm: "",
  tacno: "",
};
const defaultCmb0080 = {
  cNm: "",
  c: "",
};

export default {
  /******************************************************************************************
   * Vue 인스턴스 기본 속성(화면명, ID 등) 정의 영역	                                                       *
   ******************************************************************************************/
  name: "MSPTS213M",
  screenId: "MSPTS213M",
  /******************************************************************************************
   * Components 설정 영역
   ******************************************************************************************/
  components: {
    "ts-header": TSHeader,
    "ts-alert-popup": TSAlertPopup,
    "msp-ts-107p": MSPTS107P,
    "msp-ts-106p": MSPTS106P,
    "msp-ts-204p": MSPTS204P,
    "msp-ts-205p": MSPTS205P,
    // "msp-ts-311p": MSPTS311P,
    // "msp-ts-319p": MSPTS319P,
    // "msp-ts-320p": MSPTS320P,
  },
  /******************************************************************************************
   * [Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Created 설정 영역
   ******************************************************************************************/
  created() {
    if (this.isLoggable) console.log("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::created ]___[ 시작 ]");
    let lv_vm = this;
    window.fdpbridge.exec(
      "RecordStatusPlugin",
      {},
      (result) => {
        try {
          if (result.data === "Playing") {
            lv_vm.fn_trace("info", "녹취 진행중...");
          } else if (result.data === "Stop") {
            lv_vm.fn_trace("error", "녹취 중단 !!!", result.data);
            lv_vm.fn_AlertMsg("error", "녹취가 중단되었습니다.\n중단하시겠습니까?", "아니오|예");
          }
        } catch (error) {
          lv_vm.fn_trace("error", "녹취 진행 중 오류 발생");
        }
      },
      () => {
        lv_vm.fn_trace("error", "녹취 진행 중 오류 발생");
      }
    );
    if (this.isLoggable) console.log("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::created ]___[ 종료 ]");
  },
  /******************************************************************************************
   * [Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Before-Mount 설정 영역
   ******************************************************************************************/
  mounted() {
    if (this.isLoggable) console.log("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::mounted ]___[ 시작 ]");
    if (this.isLoggable) console.log("+++++++++");
    if (this.isLoggable) console.log("+ ▶ [라우터내장객체-(수신)-파라미터]", " this.$route.params → ", this.$route.params);
    if (this.isLoggable) console.log("+++++++++");
    // let _cloneDeep = require ('lodash/cloneDeep');
    if (this.isLoggable) console.log("_cloneDeep", _cloneDeep);
    // this.pParams = this.$_cloneDeep (this.$route.params);
    this.pParams = _cloneDeep(this.$route.params);
    if (this.isLoggable) console.log("+++++++++");
    if (this.isLoggable) console.log("+ ▶ [사용자라우터객체-(저장)-파라미터]", " this.pParams → ", this.pParams);
    if (this.isLoggable) console.log("+++++++++");
    // ---------------------------
    // (프로세스 연결):::
    //   → 업무화면간 이동 및 연계팝업 호출 시, (필수)인수 송수신 설정처리 추가
    // ---------------------------
    if (undefined != this.pParams && !TSCommUtil.gfn_isNull(this.pParams.tacno)) {
      let _rParamObj = {};
      _rParamObj.prevScrnNm = TSCommUtil.gfn_isNull_replace(this.$router.name, ""); // MSPTS203M(신탁입금)
      _rParamObj.accSeq = TSCommUtil.gfn_isNull_replace(this.pParams.accSeq, "");
      _rParamObj.baseDate = TSCommUtil.gfn_isNull_replace(this.pParams.baseDate, "");
      _rParamObj.bnkbSeq = TSCommUtil.gfn_isNull_replace(this.pParams.bnkbSeq, "");
      _rParamObj.ctno = TSCommUtil.gfn_isNull_replace(this.pParams.ctno, "");
      _rParamObj.tacno = TSCommUtil.gfn_isNull_replace(this.pParams.tacno, "");
      if ("MSPTS203M" == _rParamObj.prevScrnNm) {
        // (이전) 화면이 신탁입금일 경우
        this.ds_acno.accSeq = _rParamObj.accSeq;
        this.ds_acno.acno = _rParamObj.tacno;
        this.ds_acno.baseDate = _rParamObj.baseDate;
      } else {
        this.ds_acno.accSeq = _rParamObj.accSeq;
        // this.ds_acno.tacno = _rParamObj.tacno + _rParamObj.bnkbSeq;
        this.ds_acno.tacno = _rParamObj.tacno;
        this.ds_acno.baseDate = _rParamObj.baseDate;
      }
      this.fn_SearchValid("");
    }
    if (this.isLoggable) console.log("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::mounted ]___[ 종료 ]");
  },
  /******************************************************************************************
   * Data 설정 영역
   ******************************************************************************************/
  data() {
    if (this.isLoggable) console.log("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-MODEL:::data ]___[ 시작 ]");
    return {
      flgAprvStat: "", // 승인처리상태
      // ------
      // 개발/검증용 로깅출력 여부, added by anarchi (2023.08.7)
      // (※ 개발시: true, 운영이관시: false 설정할 것)
      // ------
      isLoggable: true,
      // ------
      // 전자서식(리포트)출력 연계개발 공통 정보항목
      // ------
      ds_searchReportList: [], // 보고서 출력조회
      ds_resultReportList: [], // 보고서 출력조회
      ds_searchReport: {}, // 보고서 출력조회
      ds_searchParam: {}, // 보고서 출력조회 파라미터(내부전용)
      // 로그인정보
      lv_basInfo: this.getStore("tsStore").getters.getBasInfo.data,
      lv_isProcess: this.getStore("tsStore").getters.getState.isProcess,
      isMobile: window.vue.getStore("deviceState").getters.getIsMobile,
      // 공통 객체
      pHeaderObj: {
        title: "송금계좌등록",
        // step: "7",
      },
      eaiCommObj: TSCommUtil.gfn_eaiCommObj(),
      // ds_list: Object.assign ({}, defaultList),
      ds_list: [],
      ds_selectedList: Object.assign({}, defaultList),
      ds_search: Object.assign({}, defaultSearch),
      ds_insert: Object.assign({}, defaultInsert),
      ds_update: Object.assign({}, defaultUpdate),
      ds_update01: Object.assign({}, defaultUpdate01),
      ds_detail: Object.assign({}, defaultDetail),
      ds_tfio42250: [], // 등록/해지 송금계좌 목록 (반환객체), added by anarchi (2023.08.14)
      ds_tfio42250_report: [], // 등록/해지 후, 전자서식 출력을 위한 정보항목
      ds_acctNm: Object.assign({}, defaultAcctNm),
      ds_printAcct: Object.assign({}, defaultPrintAcct),
      ds_cmb0080: Object.assign({}, defaultCmb0080),
      ds_cmb5095: Object.assign({}, defaultCmb5095),
      ds_acno: Object.assign({}, defaultAcno),
      ds_acnoList: Object.assign({}, defaultAcnoList),
      edt_acno: "",
      edt_pwd: "",
      edt_aprvNo: "",
      edt_amlProcNo: "",
      pAlertPopupObj: {},
      pPopup106Obj: {},
      popup106: {}, // MSPTS106P
      pPopup106Type: "",
      pPopup107Obj: {},
      popup107: {}, // MSPTS107P
      pPopup107Type: "",
      pPopup204Obj: {},
      popup204: {}, // MSPTS205P
      pPopup204Type: "",
      pPopup205Obj: {},
      popup205: {}, // MSPTS205P
      pPopup205Type: "",
      dataMode: "", //
      // pPopup319Obj: {},
      // popup319: {}, // MSPTS205P
      // pPopup319Type: "",
      // pPopup311Obj: {},
      // popup311: {}, // MSPTS205P
      // pPopup311Type: "",
      // pPopup320Obj: {},
      // popup320: {}, // MSPTS205P
      // pPopup320Type: "",
      isAmlBtnDisabled: true, // AML 버튼 활성화 여부
      isRegBtnDisabled: true, // 신규계좌등록 버튼 활성화 여부
      deleteBtn: "",
      processMsg: "",
      lv_masked_val: "", // 마스크 변수 체크
      lv_isClear: false, // 보안키패드 초기화
      lv_isProcessing: false, // 단일 화면에서 프로세스 처리 중일 때
      lv_isProcInUnit: false, // 등록/해지 버튼 눌렀을 때
    };
    if (this.isLoggable) console.log("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-MODEL:::data ]___[ 종료 ]");
  },
  /******************************************************************************************
   * Computed 설정 영역
   ******************************************************************************************/
  computed: {
    // ---------
    // (전역 사용자 기본접속정보)
    // ---------
    baseInfoObj() {
      if (this.isLoggable) console.log("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::baseInfoObj ]___[ 시작 ]");
      return this.getStore("tsStore").getters.getBasInfo.data;
      if (this.isLoggable) console.log("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::baseInfoObj ]___[ 종료 ]");
    },
    // ---------
    // (연계업무) 개별업무 / 프로세스 진행상태 여부
    // ---------
    isProcessCheck() {
      if (this.isLoggable) console.log("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isProcessCheck ]___[ 시작 ]");
      return this.getStore("tsStore").getters.getState.isProcess;
      if (this.isLoggable) console.log("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isProcessCheck ]___[ 종료 ]");
    },
    // ---------
    // (연계업무) 프로세스 진행의 마지막 여부
    // ---------
    isLastProcessCheck() {
      if (this.isLoggable) console.log("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isLastProcessCheck ]___[ 시작 ]");
      return this.$bizUtil.tsUtils.getIsLastProc(this);
      if (this.isLoggable) console.log("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isLastProcessCheck ]___[ 종료 ]");
    },
    modal1() {
      if (this.isLoggable) console.log("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::modal1 ]___[ 시작 ]");
      return this.$refs.modal1;
      if (this.isLoggable) console.log("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::modal1 ]___[ 종료 ]");
    },
    modal2() {
      if (this.isLoggable) console.log("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::modal2 ]___[ 시작 ]");
      return this.$refs.modal2;
      if (this.isLoggable) console.log("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::modal2 ]___[ 종료 ]");
    },
  },
  /******************************************************************************************
   * Methods 설정 영역
   ******************************************************************************************/
  methods: {
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /******************************************************************************
     * Function명 : fn_confirmPrintTrue, fn_confirmPrintFalse
     * 설명       : radio, mo-radio-wrapper 컴포넌트의 css error class 삭제
     ******************************************************************************/
    async fn_confirmPrintTrue() {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_confirmPrintTrue ]___[ 시작 ]");
      // ---------------------------------------------
      this.ds_searchReportList = [];
      // ---------
      // ■ [송금계좌 등록·해지 신청서 전자서식] 정보항목 매핑 정의
      //   +. 기본정보: R_IO42250.mrd  TS000053_송금계좌  등록·해지 신청서  case TS000053
      //   +. 파일경로: /cronix/fiduciary/ioamt/TFIO42250.mrd
      // ---------
      this.ds_searchParam = {}; // 초기화
      let _agreementReportURLs = ["reportTFAC20000", "selectTFCP10000"];
      if (this.isProcessCheck) {
        this.ds_searchParam.tacno = TSCommUtil.gfn_isNull(this.pParams.tacno) ? this.ds_acnoList.tacno : this.pParams.tacno;
        this.ds_searchParam.bnkbSeq = TSCommUtil.gfn_isNull(this.pParams.tacno) ? this.ds_acnoList.bnkbSeq : this.pParams.bnkbSeq;
        this.ds_searchParam.accSeq = TSCommUtil.gfn_isNull(this.pParams.tacno) ? this.ds_acnoList.accSeq : this.pParams.accSeq;
        this.ds_searchParam.ctno = TSCommUtil.gfn_isNull(this.pParams.tacno) ? Number(this.ds_acnoList.accSeq) : Number(this.pParams.accSeq);
        //
        this.ds_searchReport.reportUrl = _agreementReportURLs[0];
      } else {
        this.ds_searchParam.csId = this.ds_acno.csId;
        //
        this.ds_searchReport.reportUrl = _agreementReportURLs[1];
      }
      if (this.isLoggable) console.log("+++++++++");
      if (this.isLoggable) console.log("+ ▶ [전자서식 설정-파라미터]", "this.ds_searchParam → ", this.ds_searchParam);
      if (this.isLoggable) console.log("+ ▶ [전자서식 설정-URL]", "this.ds_searchReport.reportUrl → ", this.ds_searchReport.reportUrl);
      if (this.isLoggable) console.log("+++++++++");
      // ---------
      // (전자서명 동의서) 리포트 데이터
      // ---------
      this.ds_searchReport.reportMrdNm = "TS000001";
      this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam);
      this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)));
      // ---------------------------------------------
      /*
        As-Is 로직
        ds_param.setColumn(0, "bnkAcno", this.Div01.edt_bnkAcno.value); //거래은행계좌
        ds_param.setColumn(0, "cnm", this.cnm  ); //고객명
        ds_param.setColumn(0, "tacno", this.tacno); //종합계좌번호
        ds_param.setColumn(0, "bnkNm", this.Div01.cbo5095.text); //은행명 2021-10-05 add 보고서 출력 시 변수 변경
        ds_param.setColumn(0, "bnkDpstrNm", this.Div01.edt_bkAcNm.value); //예금주명
        ds_param.setColumn(0, "procDate", this.gfn_getBusyDate()); // 영업일
        ds_param.setColumn(0, "procEnnm", application.gds_userInfo.getColumn( 0,  "userNm" )); // 처리자
        ds_param.setColumn(0, "regMnEmno", application.gds_userInfo.getColumn( 0,  "usid" )); // 처리자 사번
        ds_param.setColumn(0, "csId", this.csId); //고객번호
        ds_param.setColumn(0, "serviceNm", "송금계좌 서비스 (등록)"); // 서비스명
        */
      // ---------------------------------------------
      // 송금계좌 등록·해지 신청서 전자서식
      // this.ds_tfio42250_report = _.cloneDeep (this.ds_list);
      if (0 < this.ds_tfio42250_report.length) {
        // debugger;
        this.ds_tfio42250_report.forEach((elmnt_rpt, idx) => {
          // if (this.ds_selectedList.bnkAcno == elmnt.bnkAcno) {
          // this.ds_searchParam = {}; // 초기화
          let _regRelAccntObj = {};
          _regRelAccntObj = this.ds_list.find((elmnt_lst) => {
            if (this.isLoggable) console.log("+++++++++");
            if (this.isLoggable) console.log("+ ▷ [배열요소간 비교]", " elmnt_rpt.bnkAcno → ", elmnt_rpt.bnkAcno, ", elmnt_lst.bnkAcno → ", elmnt_lst.bnkAcno);
            if (this.isLoggable) console.log("+++++++++");
            if (elmnt_rpt.bnkAcno === elmnt_lst.bnkAcno) {
              return true;
            }
          });
          this.mappingData = {
            rcno: this.ds_acnoList.rcno, // 실명번호 (고객계좌이력 조회 정보걕체에서 확득함.)
            bnkAcno: _regRelAccntObj.bnkAcno, // 거래은행계좌
            // tacno     : _regRelAccntObj.tacno, // 종합계좌번호 (조회 계좌정보 저장객체에서 추출)
            // tacno     : elmnt_rpt.tacno, // 종합계좌번호 (리포트 저장객체에서 추출)
            tacno: this.ds_acno.tacno, // 종합계좌번호 저장객체에서 추출
            bnkNm: _regRelAccntObj.bnkNm, // 은행명
            bnkDpstrNm: _regRelAccntObj.bnkAccNm, // 예금주명
            procDate: _regRelAccntObj.procDate, // 영업일
            // relsYn    : elmnt_rpt.relsYn, // 등록/해지구분 (등록/해지원본객체에서 추출로 변경함, modified by anarchi)
            relsYn: "Y" == elmnt_rpt.insrAccRegYn ? "Y" : "N", // 등록/해지구분 (등록/해지원본객체에서 추출로 변경함, modified by anarchi)
            // csId      : _regRelAccntObj.csId, // 고객번호
            cnm: this.ds_acno.cnm, // 고객명
            csId: this.ds_acno.csId, // 고객번호
            procEnnm: this.getStore("userInfo").getters.getUserInfo.userNm, // 처리자
            regMnEmno: this.getStore("userInfo").getters.getUserInfo.userId, // 처리자
            serviceNm: "송금계좌 서비스 (등록/해지)",
          };
          this.ds_searchReport.reportMrdNm = "TS000053"; // 서식폼ID
          this.ds_searchReport.reportUrl = "reportTFIO42250"; // 서식 PO서비스ID: R_IO42250.mrd (tfio42250)
          this.ds_searchReport.reportParam = JSON.stringify(this.mappingData);
          this.ds_searchReport.mappingData = JSON.stringify(this.mappingData);
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)));
          // }
        });
      }
      this.fn_initReport();
      this.fn_searchReport();
      // ---------------------------------------------
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_confirmPrintTrue ]___[ 종료 ]");
    },
    /***************************************************************************************
          리포트 데이터 셋팅
      *****************************************************************************************/
    fn_initReport() {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::메소드명 ]___[ 시작 ]");
      this.ds_resultReportList = [];
      for (let i = 0; i < this.ds_searchReportList.length; i++) {
        let report = {
          formId: this.ds_searchReportList[i].reportMrdNm,
        };
        report.params = this.ds_searchReportList[i];
        report.mappingData = this.ds_searchReportList[i].mappingData;
        this.ds_resultReportList.push(JSON.parse(JSON.stringify(report)));
      }
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::메소드명 ]___[ 종료 ]");
    },
    /***************************************************************************************
          리포트 데이터 조회
      *****************************************************************************************/
    async fn_searchReport() {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_searchReport ]___[ 시작 ]");
      // ---------------------------------------------------------------
      // 이탈 프로세스 처리 로직 추가, 반영, modified by 권영욱 (2023.09.21)
      if (this.lv_isProcInUnit) {
        TSInfoUtil.commReportInfo(this, this.ds_resultReportList, {}, false);
        this.lv_isProcInUnit = false;
        return;
      }
      if (this.isProcessCheck) {
        if (!this.isLastProcessCheck) {
          // TSInfoUtil.commReportInfo (this, this.ds_resultReportList, this.fn_movePage_MSPTS213M , false)
          TSInfoUtil.commReportInfo(this, this.ds_resultReportList);
        } else {
          TSInfoUtil.commReportInfo(this, [], function() {}, true, "", true);
        }
      } else {
        // 프로세스 진행 중이 아닐 경우
        TSInfoUtil.commReportInfo(this, [], function() {}, true, "", true);
      }
      // ---------------------------------------------------------------
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_searchReport ]___[ 종료 ]");
    },
    /******************************************************
     * Function명 : fn_checkProcess
     * 설명       : 프로세스에 따라 분기 처리
     ******************************************************/
    fn_checkProcess() {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_checkProcess ]___[ 시작 ]");
      let lv_Vm = this;
      let tmpContentTitle = "송금계좌등록/해지 완료";
      let tmpContent = [];
      let t_type = 0;
      if (this.isProcessCheck) {
        if (!this.isLastProcessCheck) {
          tmpContent.push("송금계좌등록/해지");
          if (this.baseInfoObj.procTyp === "custInfoReg") {
            tmpContent.push("송금계좌등록 등록/해지 화면으로 이동합니다.");
          }
          t_type = 7; // 프로세스 진행 중 마지막 화면이 아닐 경우
        } else {
          t_type = 9; // 프로세스 진행 중 마지막 화면일 경우
        }
      } else {
        // 프로세스 진행 중이 아닐 경우
        t_type = 9;
      }
      // debugger;
      let t_popupObj = {
        confirm: true,
        confirmFunc: this.fn_confirmPrintTrue,
        content: tmpContent,
        contentTitle: tmpContentTitle,
        confirmData: {
          // accSeq: this.pParams.accSeq,
          // bnkbSeq: this.pParams.bnkbSeq,
          // tacno: this.pParams.tacno,
          accSeq: TSCommUtil.gfn_isNull(this.pParams.tacno) ? this.ds_acnoList.accSeq : this.pParams.accSeq,
          bnkbSeq: TSCommUtil.gfn_isNull(this.pParams.tacno) ? this.ds_acnoList.bnkbSeq : this.pParams.bnkbSeq,
          tacno: TSCommUtil.gfn_isNull(this.pParams.tacno) ? this.ds_acnoList.tacno : this.pParams.tacno,
          csId: this.ds_acnoList.csId,
        },
      };
      lv_Vm.fn_AlertPopup(t_type, t_popupObj);
      if (this.isLoggable) console.log("□□□□□□□□□", "[ t_popupObj :::", t_popupObj, " ]__[ METHODS:::fn_checkProcess t_popupObj]___[ 파라미터 ]");
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_checkProcess ]___[ 종료 ]");
    },
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // =============================================
    // (이벤트 핸들러) openModal::
    // =============================================
    openModal(type) {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::openModal ]___[ 시작 ]");
      switch (type) {
        case 1:
          this.modal1.open();
          break;
        case 2:
          this.modal2.open();
          break;
      }
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::openModal ]___[ 종료 ]");
    },
    // =============================================
    // (이벤트 핸들러) closeModal::
    // =============================================
    closeModal(type) {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::closeModal ]___[ 시작 ]");
      switch (type) {
        case 1:
          this.modal1.close();
          break;
        case 2:
          this.modal2.close();
          break;
      }
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::closeModal ]___[ 종료 ]");
    },
    // =============================================
    // (흐름제어 메소드) fn_AlertPopup:: 책임자 승인요청 팝업호출 (분기)
    // =============================================
    fn_AlertPopup(type, pPopupObj) {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]_[ METHODS:::fn_AlertPopup ]__[ 시작 ]");
      if (this.isLoggable) console.log("+++++++++");
      if (this.isLoggable) console.log("+ ▷ [로컬알림-공통팝업처리용]", " type → ", type, ", pPopupObj → ", pPopupObj);
      if (this.isLoggable) console.log("+++++++++");
      switch (type) {
        case 0:
          if (!TSCommUtil.gfn_isNull(pPopupObj)) {
            this.pAlertPopupObj.type = type;
            this.pAlertPopupObj.content = pPopupObj.content;
            if (pPopupObj.confirm) {
              //ASR240100938_전자서식 발급 오류 보완
              this.pAlertPopupObj.confirm = pPopupObj.confirm;
              this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc;
            }
            if (pPopupObj.closeFunc) {
              this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc;
              this.pAlertPopupObj.closeData = pPopupObj.closeData;
            }
            this.pAlertPopupObj.single = pPopupObj.single; //ASR240100938_전자서식 발급 오류 보완
          }
          break;
        case 1:
          if (!TSCommUtil.gfn_isNull(pPopupObj)) {
            this.pAlertPopupObj.content = pPopupObj.content;
            if (pPopupObj.confirm) {
              this.pAlertPopupObj.confirm = pPopupObj.confirm;
              this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc;
            }
            if (pPopupObj.single) {
              this.pAlertPopupObj.single = pPopupObj.single;
            }
          }
          break;
        case 2:
          break;
        case 3:
          break;
        case 4:
          break;
        case 5:
          break;
        case 6:
          break;
        case 7:
          if (!TSCommUtil.gfn_isNull(pPopupObj)) {
            TSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj);
          }
          break;
        case 8:
          break;
        case 9:
          if (!TSCommUtil.gfn_isNull(pPopupObj)) {
            this.pAlertPopupObj.content = pPopupObj.content;
            this.pAlertPopupObj.contentTitle = "";
            this.pAlertPopupObj.confirm = false;
            this.pAlertPopupObj.cancel = true;
            this.pAlertPopupObj.single = false;
            this.pAlertPopupObj.type = 0;
            if (!pPopupObj.cancel) {
              this.pAlertPopupObj.cancel = pPopupObj.cancel;
            }
            if (pPopupObj.confirm) {
              this.pAlertPopupObj.confirm = pPopupObj.confirm;
              this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc;
              this.pAlertPopupObj.confirmData = pPopupObj.confirmData;
            }
            if (pPopupObj.closeFunc) {
              this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc;
              this.pAlertPopupObj.closeData = pPopupObj.closeData;
            }
            if (!TSCommUtil.gfn_isNull(type) && type != 0) {
              this.pAlertPopupObj.type = type;
            }
            if (pPopupObj.contentTitle) {
              this.pAlertPopupObj.contentTitle = pPopupObj.contentTitle;
            }
          }
          break;
      }
      this.$refs.alertPopup.fn_Open();
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]_[ METHODS:::fn_AlertPopup ]__[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_SetMaskedTxt
     * 설명       : 보안키패드 체크
     ******************************************************************************/
    fn_SetMaskedTxt(val) {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SetMaskedTxt ]___[ 시작 ]");
      if (this.isLoggable) console.log("+++++++++");
      if (this.isLoggable) console.log("+ ▶ [보안키패드 입력값]", " val → ", val);
      if (this.isLoggable) console.log("+++++++++");
      this.lv_masked_val = val;
      this.fn_PwdValid_SecuPad();
      if (this.isLoggable) console.log("+++++++++");
      if (this.isLoggable) console.log("+ ▶ [보안키패드 입력값]", " lv_masked_val → ", lv_masked_val);
      if (this.isLoggable) console.log("+++++++++");
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SetMaskedTxt ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_init
     * 설명       : 초기화
     ******************************************************************************/
    fn_init() {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_init ]___[ 시작 ]");
      this.ds_acno = Object.assign({}, defaultAcno);
      this.edt_pwd = "";
      this.ds_list = {};
      this.isRegBtnDisabled = true;
      this.isAmlBtnDisabled = true;
      this.ds_detail = {};
      this.ds_insert = {};
      this.ds_update = {};
      this.ds_search = {};
      this.ds_tfio42250 = [];
      this.dataMode = "";
      this.ds_tfio42250_report = []; // 전자서식(송금계좌 등록/해지 리포트)저장 객체 (배열)
      this.edt_pwd = ""; // 비밀번호(웹일반) 정합성 체크를 위한 초기화
      this.lv_masked_val = ""; // 비밀번호(보안키패드) 정합성 체크를 위한 초기화
      this.lv_isClear = !this.lv_isClear ? true : false; // 비밀번호(보안키패드) 정합성 체크를 위한 초기화
      this.lv_basInfo = this.getStore("tsStore").getters.getBasInfo.data;
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_init ]___[ 종료 ]");
    },
    // =============================================
    // (이벤트 핸들러) fn_Close::
    // =============================================
    fn_Close() {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_Close ]___[ 시작 ]");
      this.$refs.alertPopup.fn_Close();
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_Close ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_PwdValid
     * 설명       : 필수값 검증 후 조회
     ******************************************************************************/
    fn_PwdValid(event) {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_PwdValid ]___[ 시작 ]");
      if (TSCommUtil.gfn_length(this.edt_pwd) == 4) {
        if (TSCommUtil.gfn_length(this.ds_acno.cnm) == 0) {
          this.fn_AlertPopup(0, {
            content: "종합계좌번호를 입력하십시오.",
          });
          this.edt_pwd = "";
          return;
        }
        // ---------
        // □ 원격트랜잭션서비스(PO) 호출
        // 'C392_F10600247_S' |
        // 'UF10600247' // [NEXT 사랑On 신탁]계좌비밀번호체크-selectTFIO49000AList
        // ---------
        // 기존 검색 결과 초기화 함수 필요함 (?)
        this.eaiCommObj.lv_vm = this;
        this.eaiCommObj.auth = "S";
        // this.eaiCommObj.trnstId = "txTSSTS49S8" // selectTFIO49000AList;
        this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600247_S";
        this.eaiCommObj.params = {
          acctNo: this.ds_acno.tacno,
          pwd: this.edt_pwd,
          pwdCls: "N",
        };
        if (this.isLoggable) console.log("+++++++++");
        if (this.isLoggable) console.log("+ ▶ [계좌비밀번호체크]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
        if (this.isLoggable) console.log("+++++++++");
        // TSServiceUtil.invoke (this.eaiCommObj, this.fn_transactionResult)
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_passwordCheckResult);
      }
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_PwdValid ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_PwdValid_SecuPad
     * 설명       : 필수값 검증 후 조회 (보안키패드용)
     ******************************************************************************/
    fn_PwdValid_SecuPad(event) {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_PwdValid_SecuPad ]___[ 시작 ]");
      // if (TSCommUtil.gfn_length (this.lv_masked_val) == 4) {
      if (TSCommUtil.gfn_length(this.ds_acno.cnm) == 0) {
        this.fn_AlertPopup(0, {
          content: "종합계좌번호를 입력하십시오.",
        });
        // this.lv_masked_val = "";
        return;
      }
      // ---------
      // □ 원격트랜잭션서비스(PO) 호출
      // 'C392_F10600247_S' |
      // 'UF10600247' // [NEXT 사랑On 신탁]계좌비밀번호체크-selectTFIO49000AList
      // ---------
      // 기존 검색 결과 초기화 함수 필요함 (?)
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      // this.eaiCommObj.trnstId = "txTSSTS49S8" // selectTFIO49000AList;
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600247_S";
      this.eaiCommObj.params = {
        acctNo: this.ds_acno.tacno,
        pwd: this.edt_pwd,
        pwdCls: "N",
        // pwd: this.lv_masked_val,
      };
      if (this.isLoggable) console.log("+++++++++");
      if (this.isLoggable)
        console.log("+ ▶ [계좌비밀번호체크_(보안키패드용)]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log("+++++++++");
      // TSServiceUtil.invoke (this.eaiCommObj, this.fn_transactionResult)
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_passwordCheckResult);
      // }
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_PwdValid_SecuPad ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_passwordCheckResult
     * 설명       : 비밀번호 체크 후처리
     ******************************************************************************/
    fn_passwordCheckResult(pResultData) {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_passwordCheckResult ]___[ 시작 ]");
      if (this.isLoggable) console.log("+++++++++");
      if (this.isLoggable) console.log("+ ▶ [계좌비밀번호체크]_[EAI(PO)_(", pResultData.commHeaderVO.eaiId, ")_(송신$콜백)리턴객체]", " pResultData → ", pResultData);
      if (this.isLoggable) console.log("+++++++++");
      if (!TSCommUtil.gfn_isNull(pResultData.data.errorMsg)) {
        this.fn_AlertPopup(0, {
          content: pResultData.data.errorMsg,
        });
        this.edt_pwd = "";
        this.lv_masked_val = "";
        this.lv_isClear = false;
        return;
      }
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_passwordCheckResult ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_SearchValid
     * 설명       : 필수값 검증 후 조회
     ******************************************************************************/
    fn_SearchValid(event) {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_SearchValid ]___[ 시작 ]");
      if (TSCommUtil.gfn_length(this.ds_acno.tacno) == 7) {
        this.fn_transaction();
      }
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_SearchValid ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_transaction
     * 설명       : 계좌 정보 조회 TR 전송
     ******************************************************************************/
    fn_transaction() {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_transaction ]___[ 시작 ]");
      this.ds_search.tacno = this.ds_acno.tacno;
      // ---------
      // 'C392_F10600215_S' |
      // 'UF10600215' // [NEXT 사랑On 신탁] 고객정보이력 상세조회-selectTPUP95320
      // ---------
      // 기존 검색 결과 초기화 함수 필요함
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      // this.eaiCommObj.trnstId = "txTSSTS49S8"; // selectTFIO49000AList
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600215_S";
      this.eaiCommObj.params = {
        tacno: this.ds_search.tacno,
      };
      // TSServiceUtil.invoke (this.eaiCommObj, this.fn_transactionResult);
      if (this.isLoggable) console.log("+++++++++");
      if (this.isLoggable) console.log("+ ▶ [고객정보이력 상세조회]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log("+++++++++");
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_transactionResult);
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_transaction ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_transactionResult
     * 설명       : 계좌 정보 조회 TR 전송 후처리
     ******************************************************************************/
    fn_transactionResult(pResultData) {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_transactionResult ]___[ 시작 ]");
      if (this.isLoggable) console.log("+++++++++");
      if (this.isLoggable) console.log("+ ▶ [고객정보이력 상세조회]_[EAI(PO)_(", pResultData.commHeaderVO.eaiId, ")_(송신$콜백)-리턴객체]", " pResultData → ", pResultData);
      if (this.isLoggable) console.log("+++++++++");
      if (!TSCommUtil.gfn_isNull(pResultData.data.errorMsg)) {
        this.fn_AlertPopup(0, {
          content: pResultData.data.errorMsg,
        });
        return;
      }
      // if (this.isLoggable) console.log ("+++++++++");
      // if (this.isLoggable) console.log ("+ ▶ [계좌정보 조회 TR 전송 반환개체]", " pResultData.data → ", pResultData.data);
      // if (this.isLoggable) console.log ("+++++++++");
      this.ds_acnoList = pResultData.data;
      this.ds_acno.cnm = this.ds_acnoList.cnm;
      this.ds_acno.csId = this.ds_acnoList.csId;
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_transactionResult ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_searchList
     * 설명       : 조회 버튼 클릭
     ******************************************************************************/
    fn_searchList() {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_searchList ]___[ 시작 ]");
      // 종합계좌번호      ds_acno
      if (TSCommUtil.gfn_isNull(this.ds_acno.cnm)) {
        this.fn_AlertPopup(0, {
          confirm: false,
          single: true,
          content: "종합계좌번호를 입력하십시오.",
        });
        return;
      }
      if (this.isMobile) {
        // 종합계좌번호: ds_acno
        if (TSCommUtil.gfn_isNull(this.lv_masked_val) || TSCommUtil.gfn_length(this.lv_masked_val) != 4) {
          this.fn_AlertPopup(0, {
            confirm: false,
            single: true,
            content: "비밀번호를 입력하십시오.",
          });
          return;
        }
      } else {
        // 종합계좌번호: ds_acno
        if (TSCommUtil.gfn_isNull(this.edt_pwd) || TSCommUtil.gfn_length(this.edt_pwd) != 4) {
          this.fn_AlertPopup(0, {
            confirm: false,
            single: true,
            content: "비밀번호를 입력하십시오.",
          });
          return;
        }
        if (TSCommUtil.gfn_length(this.edt_pwd) != 4) {
          this.fn_AlertPopup(0, {
            confirm: false,
            single: true,
            content: "비밀번호를 입력하십시오.",
          });
          return;
        }
      }
      this.processMsg = "";
      this.edt_aprvNo = "";
      this.edt_amlProcNo = "";
      this.isRegBtnDisabled = true;
      this.isAmlBtnDisabled = false;
      this.ds_search.csId = this.ds_acno.csId;
      this.ds_search.tacno = this.ds_acno.tacno;
      this.ds_search.pwd = this.edt_pwd;
      this.ds_search.pwdYn = "Y";
      // ---------
      // 'C392_F10600169_S' |
      // 'UF10600169' // [NEXT 사랑On 신탁]송금계좌 목록조회-selectTFIO42250List
      // ---------
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      // this.eaiCommObj.trnstId = "txTSSTS42S1"; // selectTFIO42250List
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600169_S";
      this.eaiCommObj.params = {
        tacno: this.ds_search.tacno,
        csId: this.ds_search.csId,
        pwd: this.ds_search.pwd,
        pwdYn: this.ds_search.pwdYn,
      };
      if (this.isLoggable) console.log("+++++++++");
      if (this.isLoggable) console.log("+ ▶ [송금계좌 목록조회]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log("+++++++++");
      // TSServiceUtil.invoke (this.eaiCommObj, this.fn_callBack);
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack);
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_searchList ]___[ 종료 ]");
    },
    // =============================================
    // (이벤트 콜백) fn_callBack:: 조회 후처리
    // =============================================
    fn_callBack(pResultData) {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_callBack ]___[ 시작 ]");
      if (this.isLoggable) console.log("+++++++++");
      if (this.isLoggable) console.log("+ ▶ [송금계좌 목록조회]_[EAI(PO)_(", pResultData.commHeaderVO.eaiId, ")_(송신$콜백)-리턴객체]", " pResultData → ", pResultData);
      if (this.isLoggable) console.log("+++++++++");
      this.ds_list = pResultData.data.tfio42250;
      // ------------------
      // ※ (공통) 전자서식(리포트출력) 연계개발 관련 처리 추가, added by anarchi (2023.07.14)
      //   → fn_checkProcess 리포트 연계처리 시작
      // ------------------
      // ------------------------------------
      // ※ (프로세스 및 개별단위) 전자서식 발행을 위한 추가처리 (TOBE 변경요건에 맞추어)
      // ------------------------------------
      // this.ds_tfio42250 = _.cloneDeep (pResultData.data.tfio42250);
      this.ds_tfio42250 = [];
      if (undefined != pResultData.data.tfio42250 && 0 < pResultData.data.tfio42250.length) {
        pResultData.data.tfio42250.forEach((elmnt, idx) => {
          if (this.isLoggable) console.log("+ ⊙ this.ds_tfio42250 [", idx, "] ☞ ", elmnt);
          this.ds_tfio42250.push(elmnt);
        });
        // this.fn_sign ();
        // this.fn_checkProcess ();
        // this.fn_confirmPrintTrue ();
      }
      if (this.isLoggable) console.log("+++++++++");
      if (this.isLoggable) console.log("+ ▶▷ [발행될 송금계좌 해지 확인서(전자서식)]", " this.ds_tfio42250 → ", this.ds_tfio42250);
      if (this.isLoggable) console.log("+++++++++");
      // ------------------------------------
      this.isRegBtnDisabled = false;
      this.isAmlBtnDisabled = true;
      // ---------------------------------------------------------------
      // 이탈 프로세스 처리 로직 추가, 반영, added by 권영욱 (2023.09.21)
      if (this.ds_tfio42250_report.length > 0) {
        // 이탈 프로세스 Report 셋팅
        this.lv_isProcInUnit = true;

        // 이탈 프로세스 처리 로직 추가, 반영, added by 권영욱 (2023.09.21)
        if (this.lv_isProcInUnit) {
          // 이탈 프로세스 관련 커버 파라미터 셋팅
          this.$bizUtil.tsUtils.saveCvrPblParam(this, {
            accSeq: TSCommUtil.gfn_isNull(this.pParams.tacno) ? this.ds_acnoList.accSeq : this.pParams.accSeq,
            bnkbSeq: TSCommUtil.gfn_isNull(this.pParams.tacno) ? this.ds_acnoList.bnkbSeq : this.pParams.bnkbSeq,
            tacno: TSCommUtil.gfn_isNull(this.pParams.tacno) ? this.ds_acnoList.tacno : this.pParams.tacno,
            csId: this.ds_acnoList.csId,
            // cvrTypC: !TSCommUtil.gfn_isNull (this.confirmData.cvrTypC) ? this.confirmData.cvrTypC : '',
          });
        }

        this.fn_confirmPrintTrue();
      }
      // ---------------------------------------------------------------
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_callBack ]___[ 종료 ]");
    },
    // =============================================
    // (화면요소 제어 메소드) fn_reClass::
    // =============================================
    fn_reClass(relsYn) {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_reClass ]___[ 시작 ]");
      let classNm = "";
      if (relsYn === "Y") classNm = "possible";
      else classNm = "impossible";
      return classNm;
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_reClass ]___[ 종료 ]");
    },
    // =============================================
    // (화면요소 제어 메소드) fn_reTitle::
    //    expr:relsYn == "N" ? "등록" :( relsYn == "Y" ? "해지" : "미등록")
    //    expr:relsYn == "N" ? "해지"  : "등록"
    // =============================================
    fn_reTitle(gubun, relsYn) {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_reTitle ]___[ 시작 ]");
      if (this.isLoggable) console.log("+++++++++");
      if (this.isLoggable) console.log("+ ▶ [등록/해지/미등록_버튼레이블설정]", "+gubun → ", gubun, "+relsYn → ", relsYn);
      if (this.isLoggable) console.log("+++++++++");
      let title = "";
      if (gubun === "1") {
        if (relsYn === "Y") title = "해지";
        else if (relsYn === "N") title = "등록";
        else title = "미등록";
      } else {
        if (relsYn === "N") title = "해지";
        else title = "등록";
      }
      return title;
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_reTitle ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_sendAcctSelectedRow
     * 설명       : 등록/해지 버튼 클릭시
     ******************************************************************************/
    fn_sendAcctSelectedRow(selectedIdx) {
      if (this.isLoggable) console.log("□□□□□□□□□", "[MSPTS213M::METHODS]_[fn_sendAcctSelectedRow]__[시작]");
      // if (this.isLoggable) console.log ("+++++++++");
      // if (this.isLoggable) console.log ("+ ▷ selectedIdx ===> ", selectedIdx);
      // if (this.isLoggable) console.log ("+++++++++");
      if (this.lv_basInfo == null) {
        this.fn_AlertPopup(1, {
          content: "로그인 정보가 존재하지 않습니다",
          confirm: false,
        });
        return;
      }
      // const ds_list2 = Object.assign ({}, defaultList);
      this.ds_selectedList = this.ds_list[selectedIdx];
      // ---
      // 등록해지
      // ---
      if (this.ds_selectedList.relsYn == "N") {
        if (TSCommUtil.gfn_isNull(this.ds_acno.csId)) {
          // 종합계좌번호: ds_acno
          this.fn_AlertPopup(0, {
            content: "종합계좌번호를 입력하세요.",
          });
          return;
        }
        if (TSCommUtil.gfn_isNull(this.edt_pwd)) {
          this.fn_AlertPopup(0, {
            content: "비밀번호를 입력하세요.",
          });
          return;
        }
        let lv_text = "계좌등록을 해지하시겠습니까?";
        this.fn_AlertPopup(0, {
          content: lv_text,
          confirm: true,
          confirmFunc: this.fn_updateAcct,
        });
      }
      // ---
      // 신규등록
      // ---
      else {
        if (!TSCommUtil.gfn_isNull(this.ds_selectedList.bncrVtTacno)) {
          this.fn_AlertPopup(0, {
            content: "수익송금계좌로 등록된 계좌입니다.\n삭제가 불가합니다.",
          });
          return;
        } else if (!TSCommUtil.gfn_isNull(this.ds_selectedList.acctNo)) {
          this.fn_AlertPopup(0, {
            content: "정기지급계좌로 등록된 계좌입니다.\n삭제가 불가합니다.",
          });
          return;
        }
        let lv_text = "계좌를 등록하시겠습니까?";
        this.fn_AlertPopup(0, {
          content: lv_text,
          confirm: true,
          confirmFunc: this.fn_regAcct,
        });
      }
      if (this.isLoggable) console.log("□□□□□□□□□", "[MSPTS213M::METHODS]_[fn_sendAcctSelectedRow]__[종료]");
    },
    // =============================================
    // (업무:비동기 서비스 호출) fn_regAcct2:: ???
    // =============================================
    fn_regAcct2() {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]_[ METHODS:::fn_regAcct2 ]__[ 시작 ]");
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]_[ METHODS:::fn_regAcct2 ]__[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_regAcct
     * 설명       : 송금계좌등록
     ******************************************************************************/
    fn_regAcct() {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]_[ METHODS:::fn_regAcct ]__[ 시작 ]");
      this.ds_insert = Object.assign({}, defaultInsert); // ds_insert 초기화
      this.ds_insert.csId = this.ds_acno.csId;
      this.ds_insert.trBkcd = this.ds_selectedList.trBkcd; // 거래은행코드
      this.ds_insert.bnkAcno = this.ds_selectedList.bnkAcno; // 거래은행계좌
      this.ds_insert.bnkAccNm = this.ds_selectedList.bnkAccNm; // 은행계좌명
      this.ds_insert.reslYn = "N"; // 취소여부
      this.ds_insert.regDate = this.lv_basInfo.busyDate; // TSCommUtil.fn_strToday ()
      // this.getStore ("userInfo").getters.getUserInfo.userId //처리자사번
      this.ds_insert.regMnEmno = this.lv_basInfo.usid; //처리자사번
      this.ds_insert.aprvNo = this.edt_aprvNo;
      this.ds_insert.tacno = this.ds_acno.tacno; // 종합계좌번호
      this.ds_insert.bnkNm = this.ds_selectedList.bnkNm; // 은행명
      this.ds_insert.insrAccRegYn = this.ds_selectedList.insrAccRegYn;
      // 기존에 보험시스템에 등록되지 않은 계좌의 경우 보험시스템에 내역등록
      if (this.ds_selectedList.insrAccRegYn == "N") {
        this.ds_insert.eaiRegProcYn = "Y";
        this.ds_insert.insrAccRegYn = "Y";
      }
      // ---------
      // 'C392_F10600166_S' |
      // 'UF10600166' // [NEXT 사랑On 신탁]송금계좌 등록-insertTFIO42250
      // ---------
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "I";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600166_S";
      // ---------
      // EAI(PO) 전송 파라미터 설정
      // ---------
      this.eaiCommObj.params = {
        csId: this.ds_insert.csId,
        trBkcd: this.ds_insert.trBkcd,
        bnkAcno: this.ds_insert.bnkAcno,
        bnkAccNm: this.ds_insert.bnkAccNm,
        reslYn: this.ds_insert.reslYn,
        regDate: this.ds_insert.regDate,
        regMnEmno: this.ds_insert.regMnEmno,
        aprvNo: this.ds_insert.aprvNo,
        tacno: this.ds_insert.tacno,
        bnkNm: this.ds_insert.bnkNm,
        insrAccRegYn: this.ds_insert.insrAccRegYn,
        eaiRegProcYn: this.ds_insert.eaiRegProcYn,
        tacno: this.ds_search.tacno,
        pwd: this.ds_search.pwd,
        pwdYn: this.ds_search.pwdYn,
      };
      if (this.isLoggable) console.log("+++++++++");
      if (this.isLoggable) console.log("+ ▶ [송금계좌 등록]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log("+++++++++");
      // TSServiceUtil.invoke (this.eaiCommObj, this.fn_insertCallBack)
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_insertCallBack);
      // this.fn_OpenMSPTS106P ();
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]_[ METHODS:::fn_regAcct ]__[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_insertCallBack
     * 설명       : 송금계좌등록 후처리
     ******************************************************************************/
    fn_insertCallBack(res) {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]_[ METHODS:::fn_insertCallBack ]__[ 시작 ]");
      if (this.isLoggable) console.log("+++++++++");
      if (this.isLoggable) console.log("+ ▶ [송금계좌 등록]_[EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신$콜백)-리턴객체]", " res → ", res);
      if (this.isLoggable) console.log("+++++++++");
      this.ds_tfio42250_report = []; // 초기화
      this.ds_detail.amlProcYn = res.data.amlProcYn;
      this.ds_detail.amlProcNo = res.data.amlProcNo;
      this.ds_detail.aprvNo = res.data.aprvNo;
      this.edt_aprvNo = res.data.aprvNo;
      // ---
      // 리턴 결과를 확인하지 못해 임의로 코딩처리 (???)
      // 정상적이면 ds_detail 에 결과를 담는다.
      // this.ds_detail = pResultData.data.tfio42250;
      // edt_amlProcNo 값은 ds_detail 에서 받음
      // ---
      if (this.ds_detail.amlProcYn == "N") {
        this.edt_amlProcNo = res.data.amlProcNo;
        // AML 버튼 활성화 처리
        this.isAmlBtnDisabled = false;
        this.processMsg = "※ [AML]당일 송금계좌등록 고객인증 완료내역이 없습니다.(AML고객인증 후 사용)";
        return;
      }
      if (this.ds_detail.aprvNo > 0) {
        // 책임자 승인요청 팝업 호출하고 콜백 - as is fn_popupAfter02
        this.dataMode = "I";
        this.fn_OpenMSPTS106P();
        // return;
      } else {
        // this.ds_selectedList
        if (this.isLoggable) console.log("+++++++++");
        if (this.isLoggable) console.log("+ ▶ [송금계좌 등록/해지 전자서식 (선택) 건]", " this.ds_selectedList → ", this.ds_selectedList);
        if (this.isLoggable) console.log("+ ▶ [송금계좌 등록/해지 전자서식 (등록) 건]", " this.ds_insert → ", this.ds_insert);
        if (this.isLoggable) console.log("+ ▶ [송금계좌 등록/해지 전자서식 목록]", " this.ds_tfio42250_report → ", this.ds_tfio42250_report);
        if (this.isLoggable) console.log("+++++++++");
        // ------------------
        // ※ (공통) 전자서식(리포트출력) 연계개발 관련 처리 추가, added by anarchi (2023.07.14)
        //   → fn_checkProcess 리포트 연계처리 시작
        // ------------------
        // ------------------------------------
        // ※ (프로세스 및 개별단위) 전자서식 발행을 위한 추가처리 (TOBE 변경요건에 맞추어)
        // ------------------------------------
        if (!_.isEmpty(this.ds_insert.bnkAcno)) {
          // if (undefined != this.ds_tfio42250 && 0 < this.ds_tfio42250.length) {
          //   res.data.tfio42250.forEach ((elmnt, idx) => {
          //     if (this.isLoggable) console.log ("+ ⊙ this.ds_tfio42250[", idx, "] ☞ ", elmnt);
          //     this.ds_tfio42250.push (elmnt);
          //   });
          // }
          // else {
          //   this.ds_tfio42250 = [];
          //   this.ds_tfio42250 = _.cloneDeep (res.data.tfio42250);
          // }
          // this.ds_tfio42250_report = _.cloneDeep (res.data.tfio42250);
          // this.ds_tfio42250_report.push (this.ds_selectedList);
          this.ds_tfio42250_report.push(this.ds_insert);
          // this.fn_sign ();
          this.fn_searchList();
        }
        if (this.isLoggable) console.log("+++++++++");
        if (this.isLoggable) console.log("+ ▶▷ [발행될 송금계좌 등록 확인서(전자서식)]", " this.ds_tfio42250 → ", this.ds_tfio42250);
        if (this.isLoggable) console.log("+++++++++");
        // ------------------------------------
      }
      // 프린트 로직 처리..??
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]_[ METHODS:::fn_insertCallBack ]__[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_popupAfter02
     * 설명       : 책임자 승인 콜백
     ******************************************************************************/
    fn_popupAfter02() {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]_[ METHODS:::fn_popupAfter02 ]__[ 시작 ]");
      this.fn_regAcct();
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]_[ METHODS:::fn_popupAfter02 ]__[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_updateAcct
     * 설명       : 송금계좌해지 (PO: EAI 서비스 인터페이스 호출)
     ******************************************************************************/
    fn_updateAcct() {
      if (this.isLoggable) console.log("□□□□□□□□□", "[VUE_ID:::MSPTS213M]_[METHODS:::fn_updateAcct]__[시작]");
      if (this.isLoggable) console.log("+++++++++");
      if (this.isLoggable) console.log("+ ▷ this.edt_aprvNo → ", this.edt_aprvNo);
      if (this.isLoggable) console.log("+++++++++");
      this.ds_update = Object.assign({}, defaultUpdate); // ds_update 초기화
      this.ds_update.csId = this.ds_acno.csId;
      this.ds_update.stipSeq = this.ds_selectedList.stipSeq;
      this.ds_update.relsDate = this.lv_basInfo.busyDate; // TSCommUtil.fn_strToday ()
      this.ds_update.trBkcd = this.ds_selectedList.trBkcd;
      this.ds_update.bnkAcno = this.ds_selectedList.bnkAcno;
      this.ds_update.bnkNm = this.ds_selectedList.bnkNm;
      // ---
      // ★★-- 프로세스 및 기능오류 유발 의심구역
      // ---
      this.ds_update.aprvNo = this.edt_aprvNo;
      // this.ds_update.aprvNo = this.ds_selectedList.aprvNo;
      // ---------
      // 'C392_F10600170_S' |
      // 'UF10600170' // [NEXT 사랑On 신탁]송금계좌 수정-updateTFIO42250
      // ---------
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "U";
      // this.eaiCommObj.trnstId = "txTSSTS42U1"; // selectTFIO42250List
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600170_S";
      this.eaiCommObj.params = {
        csId: this.ds_update.csId,
        stipSeq: this.ds_update.stipSeq,
        relsDate: this.ds_update.relsDate,
        trBkcd: this.ds_update.trBkcd,
        bnkAcno: this.ds_update.bnkAcno,
        bnkNm: this.ds_update.bnkNm,
        aprvNo: this.ds_update.aprvNo, // ★★-- 프로세스 및 기능오류 유발 의심구역
        tacno: this.ds_search.tacno,
        pwd: this.ds_search.pwd,
        pwdYn: this.ds_search.pwdYn,
      };
      if (this.isLoggable) console.log("+++++++++");
      if (this.isLoggable) console.log("+ ▶▷ [송금계좌 수정(해지)]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-파라미터]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log("+++++++++");
      // TSServiceUtil.invoke (this.eaiCommObj, this.fn_updateCallBack);
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_updateCallBack);
      if (this.isLoggable) console.log("□□□□□□□□□", "[VUE_ID:::MSPTS213M]_[METHODS:::fn_updateAcct]__[종료]");
    },
    /*********************************************************
     * Function명: fn_updateCallBack
     * 설명: 송금계좌해지 후처리
     *********************************************************/
    fn_updateCallBack(pResultData) {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]_[ METHODS:::fn_updateCallBack ]__[ 시작 ]");
      if (this.isLoggable) console.log("+++++++++");
      if (this.isLoggable) console.log("+ ▶▷ [송금계좌 수정(해지)]_[EAI(PO)_(", pResultData.commHeaderVO.eaiId, ")_(송신(콜백))-리턴객체]", " pResultData → ", pResultData);
      if (this.isLoggable) console.log("+++++++++");
      this.ds_tfio42250_report = []; // 초기화
      if (pResultData.data !== null) {
        // pResultData.data.tfio42250
        // if (pResultData.body !== null) {
        this.ds_detail.amlProcYn = pResultData.data.amlProcYn;
        this.ds_detail.amlProcNo = pResultData.data.amlProcNo;
        this.ds_detail.aprvNo = pResultData.data.aprvNo;
        // ASIS 로직전체분석 진행...
        this.edt_aprvNo = this.ds_detail.aprvNo;
        if (this.ds_detail.aprvNo > 0) {
          // "책임자 승인요청" 팝업 호출하고 콜백- [ASIS] fn_popupAfter02
          this.dataMode = "U";
          this.fn_OpenMSPTS106P();
          // return;
        } else {
          if (this.isLoggable) console.log("+++++++++");
          if (this.isLoggable) console.log("+ ▶ [송금계좌 등록/해지 전자서식 (선택) 건]", " this.ds_selectedList → ", this.ds_selectedList);
          if (this.isLoggable) console.log("+ ▶ [송금계좌 등록/해지 전자서식 (해지) 건]", " this.ds_update → ", this.ds_update);
          if (this.isLoggable) console.log("+ ▶ [송금계좌 등록/해지 전자서식 목록]", " this.ds_tfio42250_report → ", this.ds_tfio42250_report);
          if (this.isLoggable) console.log("+++++++++");
          // ------------------
          // ※ (공통) 전자서식(리포트출력) 연계개발 관련 처리 추가, added by anarchi (2023.07.14)
          //   → fn_checkProcess 리포트 연계처리 시작
          // ------------------
          // ------------------------------------
          // ※ (프로세스 및 개별단위) 전자서식 발행을 위한 추가처리 (TOBE 변경요건에 맞추어)
          // ------------------------------------
          if (!_.isEmpty(this.ds_update.bnkAcno)) {
            // if (undefined != this.ds_tfio42250 && 0 < this.ds_tfio42250.length) {
            //   res.data.tfio42250.forEach ((elmnt, idx) => {
            //     if (this.isLoggable) console.log ("+ ⊙ this.ds_tfio42250[", idx, "] ☞ ", elmnt);
            //     this.ds_tfio42250.push (elmnt);
            //   });
            // }
            // else {
            //   this.ds_tfio42250 = [];
            //   this.ds_tfio42250 = _.cloneDeep (res.data.tfio42250);
            // }
            // this.ds_tfio42250_report = _.cloneDeep (pResultData.data.tfio42250);
            // this.ds_tfio42250_report.push (this.ds_selectedList);
            this.ds_tfio42250_report.push(this.ds_update);
            // this.fn_sign ();
            this.fn_searchList();
          }
        }
      }
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]_[ METHODS:::fn_updateCallBack ]__[ 종료 ]");
    },
    /*********************************************************
     * Function명: fn_OpenMSPTS107P
     * 설명: 고객조회 팝업호출
     *********************************************************/
    fn_OpenMSPTS107P(type) {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_OpenMSPTS107P ]___[ 시작 ]");
      let lv_vm = this;
      this.pPopup107Type = type;
      let properties = {
        pPage: "MSPTS213M",
        // pTacno: this.ds_acno.acno,
        // pCsId: this.ds_acno.csId,
        // pCnm: this.ds_acno.cnm,
        // pGdC: lv_vm.gdC, // 신탁상품
        // pTrstTypeC: lv_vm.trstTypC, // 신탁유형
        // pGdTypDtlC: lv_vm.gdTypDtlC, // 상품유형
        pUrl: "2000",
        pProcTc: "2000",
      };
      if (this.isLoggable) console.log("+++++++++");
      if (this.isLoggable) console.log("+ ▷▷ [계좌조회 팝업-(호출)파라미터]", " type → ", type, " properties → ", properties);
      if (this.isLoggable) console.log("+++++++++");
      this.popup107 = this.$refs.popup107.fn_Open(properties);
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_OpenMSPTS107P ]___[ 종료 ]");
    },
    /*********************************************************
     * Function명: fn_Popup107Back
     * 설명: 계좌조회 팝업호출 콜백
     *********************************************************/
    fn_Popup107Back(rtnData) {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_Popup107Back ]___[ 시작 ]");
      if (this.isLoggable) console.log("+++++++++");
      if (this.isLoggable) console.log("+ ▷ [계좌조회 팝업(콜백)-(수신)파라미터]", " rtnData → ", rtnData);
      if (this.isLoggable) console.log("+++++++++");
      this.ds_acno = rtnData;
      this.ds_acno.tacno = rtnData.tacno;
      // ------------------
      // 등록 처리 리턴 결과
      // this.tacno = rtnData.tacno;
      // this.cnm = rtnData.cnm;
      // 검색
      // 처리결과를 'Y' 인경우
      // this.fn_searchList ();
      // ------------------
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_Popup107Back ]___[ 종료 ]");
    },
    /*********************************************************
     * Function명: fn_OpenMSPTS204P
     * 설명: 신규계좌 등록 팝업 호출
     *********************************************************/
    fn_OpenMSPTS204P(type) {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_OpenMSPTS204P ]___[ 시작 ]");
      let lv_vm = this;
      this.pPopup204Type = type;
      let properties = {
        pPage: "MSPTS213M",
        pTacno: this.ds_acno.tacno,
        pCsId: this.ds_acno.csId,
        pCnm: this.ds_acno.cnm,
        // pGdC: lv_vm.gdC, // 신탁상품
        // pTrstTypeC: lv_vm.trstTypC, // 신탁유형
        // pGdTypDtlC: lv_vm.gdTypDtlC, // 상품유형
        pUrl: "2000",
        pProcTc: "2000",
      };
      if (this.isLoggable) console.log("+++++++++");
      if (this.isLoggable) console.log("+ ▷▷ [신규계좌등록 팝업]", " type → ", type, " properties → ", properties);
      if (this.isLoggable) console.log("+++++++++");
      this.popup204 = this.$refs.popup204.fn_Open(properties);
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_OpenMSPTS204P ]___[ 종료 ]");
    },
    /*********************************************************
     * Function명: fn_Popup204Back
     * 설명: 신규계좌 등록 팝업 콜백
     *********************************************************/
    fn_Popup204Back(rtnData, rtnReportData) {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_Popup204Back ]___[ 시작 ]");
      if (this.isLoggable) console.log("+++++++++");
      if (this.isLoggable) console.log("+ ▷ [신규계좌등록 팝업(콜백)]", " rtnData → ", rtnData, " rtnReportData → ", rtnReportData);
      if (this.isLoggable) console.log("+++++++++");
      // 등록 처리 리턴 결과
      // this.tacno = rtnData.tacno;
      // this.cnm = rtnData.cnm;
      // 검색
      if ("Y" == rtnData) {
        // 신규계좌등록 처리결과 'Y' 인경우
        this.ds_tfio42250_report.push(rtnReportData);
        // if (0 < rtnReportData.length) {
        //   this.ds_tfio42250_report.push (rtnReportData[0]);
        // }
        if (this.isLoggable) console.log("+++++++++");
        if (this.isLoggable) console.log("+ ▶ [송금계좌 등록/해지 전자서식 목록]", " this.ds_tfio42250_report → ", this.ds_tfio42250_report);
        if (this.isLoggable) console.log("+++++++++");
        // this.fn_sign ();
        this.fn_searchList();
      } else {
        // @TO-DO:
      }
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_Popup204Back ]___[ 종료 ]");
    },

    /*********************************************************
     * Function명: fn_OpenMSPTS205P
     * 설명: AML 팝업호출
     *********************************************************/
    fn_OpenMSPTS205P(type) {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_OpenMSPTS205P ]___[ 시작 ]");
      let lv_vm = this;
      this.pPopup205Type = type;
      let properties = {
        pPage: "MSPTS213M",
        pTacno: this.ds_acno.tacno,
        pBnkbSeq: "555",
        pCtno: "555",
        pProcSeq: this.edt_amlProcNo,
        // pProcSeq: "11",
        // pGdC: lv_vm.gdC, // 신탁상품
        // pTrstTypeC: lv_vm.trstTypC, // 신탁유형
        // pGdTypDtlC: lv_vm.gdTypDtlC, // 상품유형
        pUrl: "2000",
        pProcTc: "2000",
      };
      if (this.isLoggable) console.log("+++++++++");
      if (this.isLoggable) console.log("+ ▷▷ [AML송수신 팝업]", " type → ", type, " properties → ", properties);
      if (this.isLoggable) console.log("+++++++++");
      this.popup205 = this.$refs.popup205.fn_Open(properties);
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_OpenMSPTS205P ]___[ 종료 ]");
    },
    /*********************************************************
     * Function명: fn_Popup205Back
     * 설명: AML 등록 팝업호출 콜백
     *********************************************************/
    fn_Popup205Back(rtnData) {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_Popup205Back ]___[ 시작 ]");
      if (this.isLoggable) console.log("+++++++++");
      if (this.isLoggable) console.log("+ ▷ [AML송수신 팝업(콜백)] rtnData → ", rtnData);
      if (this.isLoggable) console.log("+++++++++");
      // 등록 처리 리턴 결과
      // this.tacno = rtnData.tacno;
      // this.cnm = rtnData.cnm;
      // 검색
      // 처리결과를 'Y' 인경우
      // this.fn_searchList ();
      this.fn_regAcct();
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_Popup205Back ]___[ 종료 ]");
    },
    /*********************************************************
     * Function명: fn_OpenMSPTS106P
     * 설명: 책임자 승인요청 팝업호출
     *********************************************************/
    fn_OpenMSPTS106P(type) {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]_[ METHODS:::fn_OpenMSPTS106P ]__[ 시작 ]");
      if (this.lv_basInfo == null) {
        this.fn_AlertPopup(1, {
          content: "로그인 정보가 존재하지 않습니다",
          confirm: false,
        });
        return;
      }
      let lv_vm = this;
      this.pPopup106Type = type;
      let fileName = ""; // this.lv_basInfo.busyDate + this.lv_basInfo.brcd + this.ds_detail.aprvNo + ".png";
      let properties = {
        pPage: "MSPTS213M",
        pProcDate: this.lv_basInfo.busyDate, // TSCommUtil.fn_strToday (),
        pBrcd: this.lv_basInfo.brcd, // this.getStore ("userInfo").getters.getUserInfo.brcd, p
        pMngAprvSeq: this.ds_detail.aprvNo,
        pFileName: fileName,
        pReqEmno: this.lv_basInfo.usid,
        // ------------------
        // ★★★ 기능 미구현 및 결함으로 인한 재작업(ASIS 기준), corrected by anarchi (2023.07.14)
        // pProcSeq: this.edt_amlProcNo, // (ASIS)
        // ------------------
        pProcSeq: this.ds_detail.edt_amlProcNo,
        pTacno: this.ds_acno.tacno,
      };
      // ---------------------------------------------------------------
      // 이탈 프로세스 처리 로직 추가, 반영, added by 권영욱 (2023.09.21)
      if (!this.isProcessCheck) {
        // 이탈 프로세스 관련 커버 파라미터 셋팅
        this.$bizUtil.tsUtils.saveCvrPblParam(this, {
          accSeq: TSCommUtil.gfn_isNull(this.pParams.tacno) ? this.ds_acnoList.accSeq : this.pParams.accSeq,
          bnkbSeq: TSCommUtil.gfn_isNull(this.pParams.tacno) ? this.ds_acnoList.bnkbSeq : this.pParams.bnkbSeq,
          tacno: TSCommUtil.gfn_isNull(this.pParams.tacno) ? this.ds_acnoList.tacno : this.pParams.tacno,
          csId: this.ds_acnoList.csId,
          // cvrTypC: !TSCommUtil.gfn_isNull (this.confirmData.cvrTypC) ? this.confirmData.cvrTypC : '',
        });
      }
      // ---------------------------------------------------------------
      if (this.isLoggable) console.log("+++++++++");
      if (this.isLoggable) console.log("+ ▷▷ [책임자승인요청 팝업]", " type → ", type, " properties → ", properties);
      if (this.isLoggable) console.log("+++++++++");
      // this.popup106 = this.$refs.popup106.fn_Open (properties);
      this.$refs.popup106.fn_Open(properties);
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]_[ METHODS:::fn_OpenMSPTS106P ]__[ 종료 ]");
    },
    /*********************************************************
     * Function명: fn_Popup106Back
     * 설명: 책임자 승인요청 팝업호출 콜백
     *********************************************************/
    fn_Popup106Back(rtnData) {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]_[ METHODS:::fn_Popup106Back ]__[ 시작 ]");
      if (this.isLoggable) console.log("+++++++++");
      if (this.isLoggable) console.log("+ ▷ [책임자승인요청 팝업(콜백)]", " rtnData → ", rtnData);
      if (this.isLoggable) console.log("+++++++++");
      // ---
      // ★★-- 프로세스 및 기능오류 유발 의심구역
      // ---
      if (!TSCommUtil.gfn_isNull(rtnData) && rtnData == "Y") {
        // ------------------
        // ★★★ 비교지시문 코딩오류로 인한 기능결함 보정함, corrected by anarchi (2023.07.14)
        //   if (this.dataMode = "I") → if (this.dataMode == "I")
        //   if (this.dataMode = "U") → if (this.dataMode == "U")
        // ------------------
        this.flgAprvStat = rtnData;
        if (this.dataMode == "I") {
          this.fn_regAcct();
        } else if (this.dataMode == "U") {
          this.fn_updateAcct();
        }
      } else {
        // this.dataMode = "";
        // apprNo 초기화
        this.ds_detail.aprvNo = "";
        this.flgAprvStat = ""; // 승인처리상태 초기화, added by anarchi (2023.07.29)
        // this.fn_searchList ();
      }
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]_[ METHODS:::fn_Popup106Back ]__[ 종료 ]");
    },
    /*********************************************************
     * Function명: fn_sign
     * 설명: 본인 인증후 전저서명
     *********************************************************/
    fn_sign() {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_sign ]___[ 시작 ]");
      if (this.isLoggable) console.log("+++++++++");
      if (this.isLoggable) console.log("+ ▶ [송금계좌 등록/해지 전자서식 목록]", " this.ds_tfio42250_report → ", this.ds_tfio42250_report);
      if (this.isLoggable) console.log("+++++++++");
      if (0 < this.ds_tfio42250_report.length) {
        // let lbl_txt = ["송금계좌 등록/해지가 완료되었습니다.\n본인인증 후 전자서명을 진행합니다."];
        // this.fn_AlertPopup (1, {
        //   content: lbl_txt,
        //   confirm: true,
        //   confirmFunc: this.fn_checkProcess,
        //   // closeFunc: this.stringDateFormat,
        // });
        this.fn_checkProcess();
      } else {
        this.fn_checkProcess();
      }
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::fn_sign ]___[ 종료 ]");
    },
    /************************************************************************************************
     * Function명 : stringDateFormat
     * 설명       :
     * @param {String} value : 처리일자
     ************************************************************************************************/
    stringDateFormat(value) {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::stringDateFormat ]___[ 시작 ]");
      if (!value) return "";
      if (value == "00010101") return "";
      else return value;
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::stringDateFormat ]___[ 종료 ]");
    },
    /************************************************************************************************
     * Function명 : dateYyMmDdFormat
     * 설명       : 날짜 형식 년월 표시 ( YYYYMMDD => YYYY-MM-DD )
     * @param {String} value : 날짜 형식 변경
     ************************************************************************************************/
    dateYyMmDdFormat(value) {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::dateYyMmDdFormat ]___[ 시작 ]");
      if (!value) return "";
      value = TSCommUtil.gfn_dateReplace(1, value);
      return value;
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::MSPTS213M ]__[ METHODS:::dateYyMmDdFormat ]___[ 종료 ]");
    },
    // ======================================================
    // (업무:데이터 제어) bnkAcnoMask:: 계좌번호 매스킹 처리
    // ======================================================
    bnkAcnoMask(val) {
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::bnkAcnoMask ]___[ 시작 ]");
      let _maskVal = val;
      if (!TSCommUtil.gfn_isNull(_maskVal)) {
        return TSCommUtil.gfn_bnkAcno_mask(_maskVal);
      }
      return _maskVal;
      if (this.isLoggable) console.log("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::bnkAcnoMask ]___[ 종료 ]");
    },
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /*
      fn_OpenMSPTS311P (type) {
        let lv_vm = this
        this.pPopup311Type = type

        let properties = {
          pPage : "MSPTS213M",
          pMsgCntn : "1100695001001",
          pUrl : "2000",                 //
          pProcTc : "2000",              //
        }
        this.popup311 = this.$refs.popup311.fn_Open (properties)
      },
      fn_Popup311Back(addmissionReq) {
        if (this.isLoggable) console.log ("fn_Popup311Back" ,addmissionReq)
      },
      fn_OpenMSPTS319P (type) {
        let lv_vm = this
        this.pPopup319Type = type

        let properties = {
          pPage : "MSPTS213M",
          pMsgCntn : "10111001097-001-001",
          pUrl : "2000",                 //
          pProcTc : "2000",              //
        }
        this.popup319 = this.$refs.popup319.fn_Open (properties)
      },
      fn_Popup319Back(name, param) {
        if (this.isLoggable) console.log ("go next page")
        if (this.isLoggable) console.log (name)
        if (this.isLoggable) console.log (param)
        this.$router.push ({name: name, params: param } );
      },
      fn_OpenMSPTS320P (type) {
        let lv_vm = this
        this.pPopup320Type = type
        let properties = {
          pPage : "MSPTS213M",
          pUrl : "2000",                 //
          pProcTc : "2000",              //
        }
        this.popup320 = this.$refs.popup320.fn_Open (properties)
      },
      fn_Popup320Back(name, param) {
        this.$router.push ({name: name, params: param } );
      },
        */
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  },
};
</script>

<style lang="scss"></style>
<style scoped></style>
